.bounding-box {
    border-radius: 30px;
}

.wallet-button {
    padding: 10px;
    margin: 10px;
    cursor: pointer;
}

.top:hover {
    border-radius: 30px 30px 0px 0px;
    background-color: #e6e6e6;
}

.bot:hover {
    border-radius: 0px 0px 30px 30px;
    background-color: #e6e6e6;
}

.title-row {
    margin: 0;
    padding: 0;
}

.title {
    font-size: 1.3em !important;
    font-weight: 600;
    text-align: center;
}

.description-row {
    margin-top: 10px;
    padding: 0;
}

.description {
    font-size: 1.2em;
    font-weight: 600;
    color: #c4c4c4;
}