.headbrand,
.datlink {
    font-size: 1.2em;
}

.headbrand:hover {
    background-color: #f0f0f0 !important;

    .fa-brands {
        background-color: transparent !important;
        color: #000 !important;
    }
}

.datlink:hover {
    background-color: #f0f0f0 !important;
}

.contact-us-link {
    cursor: pointer;
}

.contact-us-link:hover {
    background-color: #d1d1d1 !important;
}