.datlink {
    background-color: none !important;
}

.footlink:hover {
    background-color: #363636 !important;
}

.logo-foot:hover {
    background-color: #363636 !important;
}

.fa-brands:hover {
    color: #21a1f2;
}