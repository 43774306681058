.floating {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 20px;
    color: white;
    font-weight: 900;
    font-family: "Font Awesome 6 Pro";

    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;

    .title {
        font-size: 1.7em;
        text-align: center;
    }
}


@keyframes floating {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 15px);
    }

    to {
        transform: translate(0, -0px);
    }
}