.timeline-content {
    p {
        font-size: 1.1em;
    }
}


@media screen and (max-width: 800px) {
    .subtitle {
        font-size: 1em;
    }
}