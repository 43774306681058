@import 'antd/dist/antd.css';

body {
  height: unset !important;
}

.site {
  padding-top: 4.25rem;
}

// .navbar {
//   box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
// }

.navbar-item {
  font-size: 1.2em;
  font-weight: 500;

  &:focus {
    background-color: #50505000 !important;
  }
}

.title {
  font-size: 2em !important;
}

.ant-carousel .slick-dots li {
  display: none !important;
}

h3 {
  margin: 0 !important;
  padding: 0 !important;
}

a:hover {
  background-color: #d1d1d1 !important;
  color: rgb(63, 63, 63) !important;
}

.member {
  font-size: 1.5em;

  h4 {
    color: #505050;
  }
}

.navbar-item:focus {
  background-color: #00000000 !important;
}
