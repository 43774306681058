.mainhead {
    font-size: 1.4em;
    font-weight: 700;
    text-align: left;
}

.color-white {
    color: white;
}

.subtitle {
    font-size: 1.7em;
}