.ant-collapse {
    margin: 20px 0px;
    background: none;
}

.ant-collapse-item {
    border-radius: 10px !important;
    margin: 20px 0px;
}

.ant-collapse-header {
    font-size: 1.2em;
    border-radius: 10px !important;
    background-color: #f2f2f2;
}

.ant-collapse-arrow {
    font-size: 1em !important;
    margin-right: 20px !important;
}

.ant-collapse-item-active {
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);

    .ant-collapse-header {
        transition: background-color 0.2s ease;
        background-color: #5abab3;
        border-radius: 10px 10px 0 0 !important;
        color: #fff !important;
        text-shadow: 1px 1px 0 rgba(0, 0, 0, .2);
    }
}

.ant-collapse-item {
    border: none !important;
}

.ant-collapse-content-box {
    padding-top: 15px !important;
    font-size: 1.2em;
}