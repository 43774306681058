// .mint-count-box {
//     border: 1px solid #cccccc;
//     padding: 10px 20px;
//     border-radius: 10px;
//     background-color: #f8f8f8;
//     text-align: center;
//     margin: 10px 0px;

//     .label {
//         font-weight: 300;
//     }
// }

.mint-count-label {
    font-style: italic;
    font-weight: 500;
    color: #9e63e3;
}

.price-per-label {
    font-weight: 500;
    font-size: 1.2em;
    padding: 0;
    margin: 0;
}

.ant-slider-rail {
    background-color: rgb(209, 209, 209);
}

.ant-slider {
    padding: 0;
    margin: 0;
}

.nft-count-label {
    font-weight: 800;
}

.total-price-label {
    text-align: right;
    font-weight: 500;
}

.selected-nft-counts-box {
    margin: 5px 0px;
}

.status-box {
    background-color: #edf4ff;
    color: #144491;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 0;

    .status-label {
        font-weight: 800;
    }
}

.wallet-connected-label {
    text-align: center;
    border: 1px solid #aaa;
    color: #555;
    padding: 4px;
    border-radius: 6px 0 0 6px;
    margin-right: -1px;
    cursor: default;
}

.wallet-connected-button {
    border-radius: 20px !important;
    background-image: linear-gradient(to right, #8c50d1, #b78ce8) !important;
    border: none !important;
    margin: 0;
}

.wallet-connected-button-disabled {
    border-radius: 20px !important;
    background-image: linear-gradient(to right, #8c50d1, #b78ce8) !important;
    border: none !important;
    margin: 0;
    color: white !important;
}

.ant-statistic-content {
    font-size: inherit;
    color: white;
}

.ant-divider {
    margin: 5px 0 5px 0 !important;
}

.wallet-col {
    background-color: #fdc7ff;
    color: #220622;
    border-radius: 6px;
    padding: 10px 20px;
    border: 1px solid #9e63e3
}

.mint-congrats {
    padding: 20px;
    margin: 20px;
    border: 2px solid #04d74fa1;
    border-radius: 9px;
    font-weight: 600;
    background-color: #cdffcd;
    text-align: center;
}