.ant-modal-content {
    border-radius: 30px !important;
}

.title-row {
    font-size: 2em;
}

.mint-box-wrapper {
    max-width: 300px;
    min-width: 300px;
}

.mint-count-box {
    border: 1px solid #cccccc;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #f8f8f8;
    text-align: center;
    margin: 10px 0px;

    .label {
        font-weight: 300;
    }
}

.price-per-label {
    font-weight: 500;
    font-size: 1.2em;
    padding: 0;
    margin: 0;
}

.ant-slider-rail {
    background-color: rgb(209, 209, 209);
}

.ant-slider {
    padding: 0;
    margin: 0;
}

.nft-count-label {
    font-weight: 800;
}

.total-price-label {
    text-align: right;
    font-weight: 500;
}

.selected-nft-counts-box {
    margin: 5px 0px;
}

.status-box {
    background-color: #edf4ff;
    color: #144491;
    border: 1px solid #b2c1d8;
    border-radius: 6px;
    padding: 10px 20px;
    margin-bottom: 10px;

    .status-label {
        font-weight: 800;
    }
}

.submit-box {
    margin: 20px 0;
}

.wallet-connected-label {
    text-align: center;
    border: 1px solid #aaa;
    color: #555;
    padding: 4px;
    border-radius: 6px 0 0 6px;
    margin-right: -1px;
}

.wallet-connected-button {
    border-radius: 0 6px 6px 0;
}