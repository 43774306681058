.button-row {
    margin: 10px 0px;
}

.my-button {
    width: 300px;
    border-radius: 50px !important;
    background-image: linear-gradient(to right, #741fd4, #9e63e3) !important;
    color: white !important;
    cursor: pointer;
    padding: 10px;
    font-size: 1.3em;
}

.pay-with-credit-card-label {
    font-weight: 800;
}

.no-wallet-needed-label {
    font-size: 0.8em;
}

.connect-wallet-button {
    padding: 10px 0px !important;
    text-align: center;
    background: white;
    width: 300px;
    border-radius: 50px !important;
    cursor: pointer;
    padding: 5px;
    font-size: 1.3em;
    border: 3px solid #884bca !important;
    color: #884bca;
}

canvas {
    position: fixed !important;
}