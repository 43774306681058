h4 {
    color: white;
}

.fa-bounce {
    font-size: 1.3em;
}

.top-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 56px;
    height: 56px;
    margin-top: -28px;
    border-radius: 50% !important;
}

.circle-number {
    width: 26px;
    height: 26px;
    border-radius: 50% !important;
}

.bottom-circle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 56px;
    height: 56px;
    margin-bottom: -28px;
    border-radius: 50% !important;
}